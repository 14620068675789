const lighterGrey = "rgb(242, 242, 242)";
const lightGrey = "rgb(204, 204, 204)";
const Grey = "rgb(153, 153, 153)";
const Orange = "rgb(255, 153, 51)";
const Purple = "rgb(153, 153, 204)";
const Red = "rgb(255, 153, 153)";
const Blue = "rgb(102, 153, 204)";
const Black = "rgb(51, 51, 51)";
const lightBlack = "rgb(102,102,102)";

export { lighterGrey, lightGrey, Grey, Orange, Purple, Red, Blue, Black, lightBlack };